@import './styles.shared';
@import '@pog-ui-landings-library/framework/lib/mobile.production';
@import '@pog-ui-landings-library/icons/lib/mobile.production';

.s-debug-info {
  padding: $andes-spacing-16;
}

// FIXME: Alignment styles are forced for multiproduct-wrapper and business-tool. Fix with lib
.pog-ui-business-tool__title {
  text-align: center !important;
}

.pog-ui-multiproduct-wrapper {
  &__title {
    text-align: center !important;
  }
}

.modal-rebrand {
  max-width: 280px;

  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__image {
    width: auto;
    height: 100%;
  }

  &.andes-modal .andes-modal__scroll .andes-modal__header {
    padding: 0;
    height: 124px;
    background-color: #000;
    z-index: auto;
  }

  &.andes-modal .andes-modal__content {
    padding: 24px;
  }
}

.andes-modal.andes-modal--card .andes-modal__header__contents .andes-modal__title {
  margin-top: auto;
}

.andes-modal.modal-rebrand.andes-modal--card.andes-modal--asset {
  max-width: 280px !important;
}